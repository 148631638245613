import React, { useState, useEffect } from "react"
import axios from "axios"
import { SnackbarProvider } from "notistack"
import { useTranslation } from "react-i18next"

import {
  Box,
  Grid,
  Button,
  Dialog,
  Snackbar,
  TextField,
  Container,
  Typography,
  makeStyles,
  FormControl,
  ButtonGroup,
  DialogContent,
  DialogActions,
  CircularProgress,
  DialogContentText,
} from "@material-ui/core"

import SEO from "../components/seo"
import MuiAlert from "@material-ui/lab/Alert"
import CloseIcon from "@material-ui/icons/Close"
import FacebookIcon from "@material-ui/icons/Facebook"
import Logo from "../../static/images/logo/logo_signup.svg"
import StoreIcons1 from "../../static/images/Icons/store-icon.svg"
import StoreIcons2 from "../../static/images/Icons/store-icon-2.svg"
import Unlock from "../../static/images/Icons/unlock-alt-solid.svg"

const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document

const Signup = ({ pageContext }) => {
  const classes = useStyles()
  const { pLang } = pageContext
  const { t, i18n } = useTranslation()
  const emailRegExp = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
  const phoneRegexd =  /^([0|\+[0-9]{1,5})?([0-9]{10})$/
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/

  const [loading, setLoading] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [disabledBtn, setDisabledBtn] = useState(false)
  const [loadingConfirm, setLoadingConfirm] = useState(false)
  const [openAlertError, setOpenAlertError] = useState(false)
  const [errorMassegeCatch, setErrorMassegeCatch] = useState("")
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false)
  const [openGetCodeModal, setOpenVerifyCodeModal] = useState(false)
  const [openAlertErrorOnSubmit, setOpenAlertErrorOnSubmit] = useState(false)
  const [openAlertErrorSendCode, setOpenAlertErrorSendCode] = useState(false)
  const [confPassword, setConfPassword] = useState({
    confPasswordVal: "",
    showPassword: false,
  })
  const [signupFormValues, setSignupFormValues] = useState({
    userName: "",
    fullName: "",
    password: "",
    firstName: "",
    lastName: "",
    code: "",
  })
  const [errorMassege, setErrorMassege] = useState({
    password: false,
    passwordText: "",
    username: false,
    usernameText: "",
    code: false,
    codeText: "",
  })

  useEffect(() => {
    i18n.on("languageChanged", () => {
      windowGlobal.history.pushState(
        pLang,
        "signup",
        `/${i18n.language}/signup`
      )
      if (windowGlobal.history.pushState) {
        windowGlobal.history.pushState(
          pLang,
          "signup",
          `/${i18n.language}/signup`
        )
      } else {
        documentGlobal.location.href = `/${i18n.language}/signup`
      }
    })
  }, [i18n, pLang])

  const handleChangeConfPassword = prop => event => {
    setConfPassword({ ...confPassword, [prop]: event.target.value })
  }

  let jwtForProfile
  if (windowGlobal) {
    jwtForProfile = windowGlobal.sessionStorage.getItem("jwtCode")
  }

  const handleGetCodeSubmit = e => {
    e.preventDefault()
    setLoadingConfirm(true)
    setIsSubmitted(true)
    setDisabledBtn(true)

    if (enterdUserName()) {
      axios
        .post(
          `${process.env.GATSBY_API_URL}/account-confirm`,
          {
            code: signupFormValues.code,
          },
          {
            headers: {
              Authorization: `Bearer ${windowGlobal.sessionStorage.getItem(
                "jwtCode"
              )}`,
              "Accept-Language": i18n.language,
            },
          }
        )
        .then(() => {
          setDisabledBtn(false)
          setLoadingConfirm(false)
          setOpenAlertSuccess(true)
          setTimeout(() => setOpenAlertSuccess(false), 2000)
          setTimeout(() => setOpenVerifyCodeModal(false), 1500)
          setTimeout(() => 
          // windowGlobal.location.reload(true)
          windowGlobal.location.href = `/${i18n.language}/my-profile`
          , 2500)
          // .then(
          // function (res) {
          axios
            .post(
              `${process.env.GATSBY_API_URL}/auth/local`,
              {
                identifier: signupFormValues.userName,
                password: signupFormValues.password,
              },
              { headers: { "Accept-Language": i18n.language } }
            )
            .then(response => {
              windowGlobal.sessionStorage.setItem(
                "userRl",
                response.data.user.role.name
              )
              windowGlobal.sessionStorage.setItem("userId", response.data.user.id)
            })

          // }
          // )
        })
        .catch(err => {
          setLoadingConfirm(false)
          setOpenAlertErrorSendCode(true)
          setDisabledBtn(false)
          setTimeout(() => setOpenAlertErrorSendCode(false), 2000)
          setErrorMassegeCatch(err.data.message)
        })
    } else {
      setLoadingConfirm(false)
      setDisabledBtn(false)
      // setOpenAlertErrorForgotOnSubmit(true)
      // setTimeout(() => setOpenAlertErrorForgotOnSubmit(false), 2000)
    }
  }

  const resendCode = () => {
    axios
      .get(`${process.env.GATSBY_API_URL}/resend-account-confirm-code`, {
        headers: {
          Authorization: `Bearer ${jwtForProfile}`,
          "Accept-Language": i18n.language,
        },
      })
      // .then(res => {})
      // .catch(err => {})
  }

  const handleCloseGetCodeModal = () => {
    setOpenVerifyCodeModal(false)
  }

  const handleChange = prop => event => {
    if (prop === "userName" && isSubmitted) {
      enterdUserName()
    }
    setSignupFormValues({ ...signupFormValues, [prop]: event.target.value })
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setOpenAlertSuccess(false)
    setOpenAlertError(false)
    setOpenAlertErrorOnSubmit(false)
  }

  const enterdPaddwordValidation = () => {
    if (signupFormValues.password === confPassword.confPasswordVal) {
      if (!passwordRegex.test(signupFormValues.password)) {
        setErrorMassege({
          ...errorMassege,
          password: true,
          passwordText: t("errors.passwordEntriesWrong"),
        })
        return false
      } else {
        setErrorMassege({ ...errorMassege, password: false, passwordText: "" })
        return true
      }
    } else {
      setErrorMassege({
        ...errorMassege,
        password: true,
        passwordText: t("errors.passwordNotMatch"),
      })
      return false
    }
  }

  const enterdUserName = () => {
    if (emailRegExp.test(signupFormValues.userName)) {
      setErrorMassege({ ...errorMassege, username: false, usernameText: "" })
      return true
    } else if (phoneRegexd.test(signupFormValues.userName)) {
      setErrorMassege({ ...errorMassege, username: false, usernameText: "" })
      return true
    } else {
      setErrorMassege({
        ...errorMassege,
        username: true,
        usernameText: t("errors.invalidUserName"),
      })
      return false
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)
    setIsSubmitted(true)
    setDisabledBtn(true)

    if (enterdUserName() && enterdPaddwordValidation()) {
      axios
        .post(
          `${process.env.GATSBY_API_URL}/auth/local/register`,
          {
            username: signupFormValues.userName,
            password: signupFormValues.password,
            firstName: signupFormValues.firstName,
            lastName: signupFormValues.lastName,
            fullName: signupFormValues.fullName,
          },
          { headers: { "Accept-Language": i18n.language } }
        )
        .then(function (res) {
          setDisabledBtn(false)
          setLoading(false)
          setOpenVerifyCodeModal(true)
            res.data.jwt && windowGlobal.sessionStorage.setItem("jwtCode", res.data.jwt)
        })
        .catch(err => {
          setLoading(false)
          setOpenAlertError(true)
          setDisabledBtn(false)
          setTimeout(() => setOpenAlertError(false), 2000)
          setErrorMassegeCatch(err.response.data.message)
        })
    } else {
      setLoading(false)
      setTimeout(() => setOpenAlertErrorOnSubmit(false), 2000)
      setOpenAlertErrorOnSubmit(true)
      setDisabledBtn(false)
    }
  }

  if (documentGlobal) {
    if (documentGlobal.getElementById("code")) {
      documentGlobal
        .getElementById("code")
        .addEventListener("keypress", function (evt) {
          if (evt.key.charCodeAt() < 48 || evt.key.charCodeAt() > 57) {
            evt.preventDefault()
          }
        })
    }
  }

  return (
    <>
      <SEO title="Signup" lang={i18n.language} />
      <Container className={classes.mainContainer}>
        <Grid container directoin="row" justify="center" alignItems="center">
          <Grid
            item
            md={4}
            xs={12}
            className={`${classes.textCenter} ${classes.logoTextSide}`}
          >
            <img src={Logo} alt="logo" />
            <Typography variant="h5" component="h3">
              {t("signup.purposefulBook")}
            </Typography>
            <Box display="flex" justifyContent="center">
              <Box className={classes.footerStoreIcons}>
                <a href="https://play.google.com/store/apps/details?id=com.arafas.kitabhadef">
                  <img src={StoreIcons1} alt="Google Play" />
                </a>
              </Box>
              <Box className={classes.footerStoreIcons}>
                <a href="https://apps.apple.com/us/app/كتابي-الهادف/id1565685455">
                  <img src={StoreIcons2} alt="App Store" />
                </a>
              </Box>
            </Box>
          </Grid>
          <Grid item md={2} xs={false}></Grid>
          <Grid item md={6} xs={12} className={classes.textCenter}>
            <Typography
              className={classes.createAccountTitle}
              variant="h4"
              component="h3"
            >
              {t("signup.createAccount")}
            </Typography>
            <form onSubmit={handleSubmit}>
              <FormControl
                fullWidth
                className={`${classes.margin} ${classes.formConrolSignup}`}
              >
                <Grid
                  container
                  directoin="row"
                  justify="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={6}>
                    <TextField
                      required
                      fullWidth
                      id="firstName"
                      type="text"
                      label={t("signup.firstName")}
                      variant="outlined"
                      value={signupFormValues.firstName}
                      onChange={handleChange("firstName")}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      fullWidth
                      id="lastName"
                      type="text"
                      label={t("signup.lastName")}
                      variant="outlined"
                      value={signupFormValues.lastName}
                      onChange={handleChange("lastName")}
                    />
                  </Grid>
                </Grid>
              </FormControl>
              <FormControl
                fullWidth
                className={`${classes.margin} ${classes.formConrolSignup}`}
              >
                <TextField
                  id="userName"
                  type="text"
                  label={t("signup.username")}
                  variant="outlined"
                  onChange={handleChange("userName")}
                  error={errorMassege.username}
                  helperText={errorMassege.usernameText}
                />
              </FormControl>
              <FormControl
                className={`${classes.margin} ${classes.formConrolSignup}`}
                fullWidth
              >
                <TextField
                  required
                  id="password"
                  name="password"
                  label={t("signup.password")}
                  variant="outlined"
                  type={confPassword.showPassword ? "text" : "password"}
                  value={signupFormValues.password}
                  error={errorMassege.password}
                  helperText={errorMassege.passwordText}
                  onChange={handleChange("password")}
                />
              </FormControl>
              <FormControl
                className={`${classes.margin} ${classes.formConrolSignup}`}
                fullWidth
              >
                <TextField
                  required
                  id="confPassword"
                  name="confPassword"
                  label={t("signup.passwordConfirm")}
                  variant="outlined"
                  type={confPassword.showPassword ? "text" : "password"}
                  value={confPassword.confPasswordVal}
                  error={errorMassege.password}
                  helperText={errorMassege.passwordText}
                  maxLength="5"
                  minLength="1"
                  onChange={handleChangeConfPassword("confPasswordVal")}
                />
              </FormControl>
              <ButtonGroup
                fullWidth={true}
                color="primary"
                aria-label="Create account"
                disabled={disabledBtn}
                // loading={loading}
                className={`${classes.button} ${classes.margin} ${classes.buttonSignupSite} button`}
              >
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.createAccountBtn}
                >
                  {loading && <CircularProgress size={26} />}
                  {!loading && t("signup.createBtn")}
                </Button>
              </ButtonGroup>
            </form>
            <ButtonGroup
              fullWidth={true}
              color="primary"
              aria-label="Create account with facebook"
              className={`${classes.button} ${classes.margin} button`}
            >
              <Button
                href={`${process.env.GATSBY_API_URL}/connect/facebook/redirect`}
                variant="contained"
                className={classes.createAccountBtn}
                startIcon={<FacebookIcon />}
              >
                {t("signup.createFacebook")}
              </Button>
            </ButtonGroup>
            <ButtonGroup
              fullWidth={true}
              color="secondary"
              aria-label="Create account with google"
              className={`${classes.button} ${classes.margin} button`}
            >
              <Button
                href={`${process.env.GATSBY_API_URL}/connect/google/redirect`}
                variant="contained"
                className={classes.createAccountBtn}
                startIcon={
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="google"
                    className={`${classes.googleSvgIson} svg-inline--fa fa-google fa-w-16`}
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 488 512"
                  >
                    <path
                      fill="currentColor"
                      d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                    ></path>
                  </svg>
                }
              >
                {t("signup.createGoogle")}
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>

        {/* <ConfirmCodeModule
          errorMassegeCatch=""
          openGetCodeModal={openGetCodeModal}
          disabledBtn={disabledBtn}
          loadingConfirm={loadingConfirm}
          errorMassege={errorMassege}
          handleChange={handleChange}
          handleCloseGetCodeModal={handleCloseGetCodeModal}
          handleGetCodeSubmit={handleGetCodeSubmit}
        /> */}

        <Dialog
          open={openGetCodeModal}
          onClose={handleCloseGetCodeModal}
          className={classes.dialogForm}
          aria-labelledby="form-dialog-title"
        >
          <DialogActions className={classes.dialogMainActions}>
            <Button
              onClick={handleCloseGetCodeModal}
              color="primary"
              className={`${classes.dialogActionsBtns} closeButton`}
            >
              <CloseIcon />
            </Button>
          </DialogActions>
          <DialogContent className={classes.dialogMainContent}>
            <img
              src={Unlock}
              alt="Unlock"
              className={classes.dialogSigninIcon}
            />
            <DialogContentText>
              {t("signup.verifyAccount")}
              <br />{" "}
              <Typography component="span" style={{ color: "#000" }}>
                {t("signup.signupVerificationCodeSentTo")}
              </Typography>
            </DialogContentText>
            <form onSubmit={handleGetCodeSubmit}>
              <Grid container>
                <Grid item md={12} sm={12} xs={12}>
                  <FormControl
                    fullWidth
                    className={`${classes.marginForForms} ${classes.formsLabel}`}
                  >
                    <TextField
                      // required
                      id="code"
                      type="text"
                      label={t("signup.code")}
                      variant="outlined"
                      onChange={handleChange("code")}
                      error={errorMassege.code}
                      helperText={errorMassege.codeText}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                justify="space-between"
                className={classes.marginForForms}
              >
                <Grid item xs={12}>
                  <ButtonGroup
                    width="100%"
                    fullWidth={true}
                    color="primary"
                    aria-label="outlined primary button group"
                  >
                    <Button
                      disabled={disabledBtn}
                      // loading={loadingConfirm.loadingConfirm}
                      onClick={handleGetCodeSubmit}
                      variant="contained"
                    >
                      {loadingConfirm && <CircularProgress size={26} />}
                      {!loadingConfirm && t("signin.verifyCode")}
                    </Button>
                    <Button
                      disabled={disabledBtn}
                      onClick={resendCode}
                      // loading={loadingConfirm.loadingConfirm}
                    >
                      {loadingConfirm && <CircularProgress size={26} />}
                      {!loadingConfirm && t("signin.resendCode")}
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <SnackbarProvider maxSnack={3} className={classes.snackBarStyle}>
            <Snackbar
              open={openAlertErrorSendCode}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="error">
                {errorMassegeCatch}
              </Alert>
            </Snackbar>
          </SnackbarProvider>
        </Dialog>
        <SnackbarProvider maxSnack={3}>
          <Snackbar
            open={openAlertSuccess}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="success">
              {t("signup.successfullySignedUp")}
            </Alert>
          </Snackbar>
          <Snackbar
            open={openAlertError}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="error">
              {errorMassegeCatch}
            </Alert>
          </Snackbar>
          <Snackbar
            open={openAlertErrorOnSubmit}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="error">
              {t("errors.fieldsWrong")}
            </Alert>
          </Snackbar>
        </SnackbarProvider>
      </Container>
    </>
  )
}

export default Signup

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  logoSvg: {
    maxWidth: 180,
  },
  textCenter: {
    textAlign: "center",
  },
  buttonSignupSite: {
    marginTop: 30,
    marginBottom: 30,
  },
  form: {
    "& .react-tel-input": {
      marginBottom: 15,
      direction: "ltr",
      flip: false,
      "& input": {
        width: "100%",
      },
      "& .react-tel-input .form-control": {
        width: "100% important",
      },
    },
  },
  footerBoxes: {
    "& img": {
      height: 41,
      width: 26,
      margin: "0 auto 5px",
    },
  },
  footerStoreIcons: {
    margin: theme.spacing(1),
    "& img": {
      margin: "initial",
      width: "112px",
    },
  },
  formConrolSignup: {
    padding: 0,
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
    },
    "& input": {
      padding: "10px 12px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 13px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  createAccountBtn: {
    height: 40,
    borderRadius: 10,
  },
  logoTextSide: {
    "& .MuiTypography-root": {
      color: "#003e96",
      fontWeight: "bold !important",
    },
  },
  mainContainer: {
    marginTop: "2.5rem",
    marginBottom: "4rem",
  },
  createAccountTitle: {
    color: "#003e96",
    marginBottom: "1rem",
    fontWeight: "bold !important",
  },
  marginTopForForm: {
    marginTop: theme.spacing(1.5),
  },
  marginForForms: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dialogSigninIcon: {
    width: "6rem",
    height: "6rem",
    fontSize: "100%",
    fill: "#003e96",
  },
  dialogMainContent: {
    textAlign: "center",
    width: "24rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  googleSvgIson: {
    width: 18,
    height: 18,
  },
}))

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}